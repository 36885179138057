export const sortByName = items => {
  return items.sort((a, b) => {
    const nameA = a.name ? a.name.toUpperCase() : a.firstName.toUpperCase();
    const nameB = b.name ? b.name.toUpperCase() : b.firstName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
};

export const sortByFullName = items => {
  items.sort((a, b) =>
    `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)
  );

  return items;
};
