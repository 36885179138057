import { FC, Ref, forwardRef } from 'react';
import Datepicker from 'react-datepicker';
import moment from 'moment';
import { formatDate, dateFormatForUI } from '../../../utils/date';
import useIsMobile from '../../../hooks/useIsMobile';

// components
import Textbox from '../textbox';

interface Props {
  value?: Date;
  onChange?: (any) => any;
  name?: string;
  placeholder?: string;
  dateFormat?: string;
  minDate?: Date;
  dataCy?: string;
  meta?: { error?: string };
  note?: string;
  className?: string;
  wrapperClassName?: string;
  calendarClassName?: string;
}

const DatepickerField: FC<Props> = (
  {
    value,
    onChange,
    name = 'bookingDate',
    placeholder = 'Select date',
    dateFormat = dateFormatForUI,
    minDate,
    dataCy = 'datePickerField',
    meta: { error } = {},
    note,
    className,
    wrapperClassName = 'datepicker-field',
    calendarClassName = 'datepicker-field__popup',
    ...props
  },
  forwardedRef: Ref
) => {
  const isMobileView = useIsMobile();

  const textbox = (
    <Textbox
      label={placeholder}
      showDropdownArrow
      readonly
      dataCy={dataCy}
      error={!!error}
      errorMsg={error}
      note={note}
      className={className}
    />
  );

  return (
    <Datepicker
      ref={forwardedRef}
      wrapperClassName={wrapperClassName}
      calendarClassName={calendarClassName}
      showPopperArrow={false}
      customInput={textbox}
      enableTabLoop={false}
      {...props}
      name={name}
      onChange={onChange}
      placeholderText={placeholder}
      value={value ? formatDate(value, dateFormat) : undefined}
      selected={value ? moment(value).toDate() : undefined}
      minDate={moment(minDate).toDate()}
      withPortal={isMobileView}
    />
  );
};

export default forwardRef(DatepickerField);
