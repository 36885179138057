import { every, isEmpty } from 'lodash';
import { sortByName } from './sortBy';

import { i18n as I18n } from './i18n';

export const checkedOrAggregated = (itemAggs, item) => {
  return (
    item.checked ||
    itemAggs.some(itemAgg => {
      return itemAgg.id.toString() === item.value.toString();
    })
  );
};

export const isVisible = item => {
  return item.visible;
};

export const setVisibility = (filterItems, aggs) => {
  return filterItems.map(filterItem => {
    return {
      ...filterItem,
      visible: checkedOrAggregated(aggs, filterItem),
    };
  });
};

export const isChecked = i => {
  return i.checked;
};

export const aggItemsToCheckboxFilterItem = aggItems => {
  return {
    ...aggItems,
    value: aggItems.id,
    checked: false,
    applied: false,
    visible: true,
  };
};

export const isApplied = item => {
  return item.applied;
};

export const isEmptyFilters = items => {
  return isEmpty(items.filter(isVisible));
};

export const allVisibleSelected = items => {
  return every(items.filter(isVisible), 'checked');
};

const byName = item => {
  return item.name;
};

export const appliedFilterNames = items => {
  return items.filter(isApplied).map(byName);
};

export const displayOptions = items => {
  return sortByName(items).filter(isVisible);
};

export const translatedNames = (items, i18nLookup) => {
  return items.map(item => {
    return {
      ...item,
      name: I18n.t(`${i18nLookup}.${item.value}`),
    };
  });
};

export const dynamicFilter = (filterName, filters, aggs) => {
  if (filters.latestAppliedFilter !== filterName) {
    return setVisibility(filters[filterName], aggs[filterName]);
  }

  return filters[filterName];
};
