import { FC, Ref, forwardRef } from 'react';
import classNames from 'classnames';

import InputError from '../inputError';

interface Option {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  options?: Option[];
  value?: string | number;
  loading?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
  onChange?: (any) => any;
  handleChangeCallback?: (any) => any;
  error?: boolean;
  errorMsg?: string;
  testid?: string;
}

const SelectList: FC<Props> = (
  {
    loading = false,
    label,
    placeholder,
    name,
    options = [],
    value = '',
    hideLabel = true,
    disabled = false,
    onChange = () => {},
    handleChangeCallback = () => {},
    error = false,
    errorMsg = '',
    testid = 'selectList',
    children,
    ...props
  },
  forwardedRef: Ref
) => {
  const className = classNames('field', 'field--select-list', {
    'field--hidden-label': !!hideLabel,
    'field--loading': !!loading,
    'field--disabled': !!disabled,
    'field--has-error': !!error,
  });

  const handleSelectChange = event => {
    const { value } = event.target;
    handleChangeCallback(event);
    onChange(value);
  };

  const renderOption = option => (
    <option
      key={option.value}
      value={option.value}
      disabled={!!option.disabled}
    >
      {option.label}
    </option>
  );

  const placeholderOption = placeholder ? (
    <option value="">{placeholder}</option>
  ) : null;

  return (
    <div className={className} data-testid={testid}>
      <label className="field__label" htmlFor={name}>
        {label}
      </label>

      <div className="field__input field__input--select-list select-list">
        <select
          ref={forwardedRef}
          data-testid="selectListSelect"
          id={name}
          {...props}
          name={name}
          value={value}
          onChange={handleSelectChange}
          disabled={!!disabled}
        >
          {placeholderOption}
          {children || options.map(renderOption)}
        </select>

        {!!loading && (
          <div className="field__loading" date-testid="selectLoader" />
        )}
      </div>
      {error && <InputError errorMsg={errorMsg} />}
    </div>
  );
};

export default forwardRef(SelectList);
