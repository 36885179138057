import React from 'react';
import PropTypes from 'prop-types';

import { i18n as I18n } from '../../../utils/i18n';

const Spinner = ({ className }) => {
  return (
    <img
      className={className}
      src="/images/spinner.gif"
      alt={I18n.t('please_wait')}
      data-testid="loadingSpinner"
    />
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: 'spinner',
};

export default Spinner;
